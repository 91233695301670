@import '../../../styles';
@import '../../../styles/base';

.resource-card {
  background-color: $color-primary-navy;
  height: 33.25rem;
  margin: 0 2rem 1.5rem 0;
  width: 32rem;

  &-image {
    height: 16.625rem;
    object-fit: cover;
    width: 32rem;
  }

  &-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 16.625rem;
    justify-content: space-between;
    overflow: hidden;
    padding: 1.5rem;
    position: relative;
    width: 32rem;
  }

  &-name {
    @include font-subtitle-1;
    color: #fff;
  }

  &-tags {
    @include shared-tags-style;
  }

  &-tag {
    @include shared-tag-style;
    @include shared-tag-color;
  }

  &-view {
    @include font-linked-text;
    background: none;
    border: transparent;
    color: #fff;
    text-align: left;
    width: 8rem;

    &-arrow {
      margin-left: 0.5rem;
    }
  }

  &-hidden {
    background-color: $color-primary-navy;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 16.625rem;
    justify-content: space-between;
    left: 0;
    overflow: scroll;
    padding: 1.5rem 3.75rem 1.5rem 1.5rem;
    position: absolute;
    top: 16.625rem;
    transition: top 0.5s;
    width: 32rem;

    &.visible {
      top: 0;
    }
  }

  &-button {
    align-items: center;
    background-color: #fff;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 1.25rem;
    justify-content: center;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    width: 1.25rem;
  }

  &-description {
    @include font-text;
    color: #fff;
  }

  &-link {
    @include font-linked-text;
    color: #fff;
  }

  @media(max-width: $site-size-small) {
    flex-direction: column;
    height: calc(8.5rem + 16.625rem);
    width: 20.5rem;

    &-content {
      width: unset;
    }

    &-hidden {
      padding: 1rem 2rem 1rem 1rem;
      width: unset;
    }

    &-button {
      height: 1.25rem;
      right: 0.75rem;
      top: 0.75rem;
      width: 1.25rem;
    }

    &-image {
      height: 8.5rem;
      width: 100%;
    }

    &-content {
      min-height: 10.5rem;
      padding: 1rem;
    }

  }
}
