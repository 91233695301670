// Colors
$color-primary-navy: #1c2b4a;
$color-primary-blue: #2a6ca6;
$color-primary-mustard: #c09200;
$color-primary-dark-yellow: #f8cd00;
$color-accent-teal: #4fbed6;
$color-accent-orange: #ec9200;
$color-accent-yellow: #f0e500;
$color-accent-green: #739635;
$color-background-grey: #eff1f2;
$color-neutrals-black: #000;
$color-neutrals-white: #fff;
$color-neutrals-dark-grey: #747678;
$color-neutrals-grey: #b7b7b7;
$color-neutrals-metallic-gold: #7f754f;
$color-tag-light-blue: #d6e2f8;
$color-tag-white: #fff;
$color-tag-light-red: #f8d6de;
$color-tag-light-green: #80f674;
$color-tag-light-yellow: #f5e991;
$color-tag-light-brown: #fed5a7;
$color-tag-mint: #83f9ca;
$color-gradients-teal-white: linear-gradient(90deg, #2a6ca6 0%, #4fbed6 100%);
$color-gradients-blue-teal: linear-gradient(90deg, #4fbed6 0%, #fff 100%);
$color-background-grey: #eff1f2;

// Numbers
$site-min-width: 320px;
$site-size-small: 1199px;
