@import '../../../styles';

.resource-page {
  &-title {
    @include font-heading-1;
  }

  &-text {
    @include font-text;
    margin-top: 1.5rem;
  }

  &-content {
    margin: 3rem -7rem -3rem;
    padding: 3rem 7rem;
  }

  &-map {
    border-radius: 3px;
    border-style: solid;
    border-width: 6px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    max-height: 60%;
    max-width: 50%;
    position: relative;

    &-iframe {
      aspect-ratio: 4/3;
      display: block;
      height: 50%;
      width: 100%;
    }
  }

  &-heading {
    @include font-heading-2;
  }

  &-resource {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 3rem -2rem -1.5rem 0;
  }

  &-tag-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2.25rem;
  }

  &-tag-button {
    @include font-subtitle-1;
    background-color: #fff;
    border: 0;
    border-bottom: solid 2px $color-neutrals-grey;
    color: $color-neutrals-grey;
    flex: 1;
    padding: 1rem 1.5rem;

    &.selected {
      border-bottom: solid 5px $color-primary-blue;
      color: $color-neutrals-black;
    }
  }


  &-mobile-dropdown {
    display: none;

    .dropdown-root {
      height: 2.5rem;
      margin-top: 1rem;

      .dropdown-control {
        border-radius: 4px;
      }

      .dropdown-arrow {
        margin-top: 0.25rem;
      }
    }
  }

  @media(max-width: $site-size-small) {
    &-tag-section {
      display: none;
    }

    &-mobile-dropdown {
      display: unset;
    }

    &-content {
      margin: 1.5rem -1.5rem -1.5rem;
      padding: 1.5rem;
    }
  }
}
