/* Same Properties for Organization & Resource*/
@mixin shared-tags-style {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.25rem;
} 

@mixin shared-tag-style {
  @include font-small-button-text;
  align-items: center;
  border-radius: 1rem;
  color: $color-primary-navy;
  display: flex;
  flex-shrink: 0;
  font-weight: bold;
  height: 1.75rem;
  margin: 0.75rem 0.75rem 0 0;
  padding: 0 0.75rem;
    
  &:last-child {
    margin-right: 0;
  }
}

@mixin shared-tag-color {
  &.white {
    background-color: $color-tag-white;
  }
  
  &.light-green {
    background-color: $color-tag-light-green;
  }
  
  &.light-yellow {
    background-color: $color-tag-light-yellow;
  }
  
  &.light-red {
    background-color: $color-tag-light-red;
  }
  
  &.light-blue {
    background-color: $color-tag-light-blue;
  }

  &.light-brown {
    background-color: $color-tag-light-brown;
  }

  &.mint {
    background-color: $color-tag-mint;
  }
}
