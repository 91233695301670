@import './styles/reset';
@import './styles/fonts';

html,
body,
#root {
  font-family: $font-family;
  height: 100%;
  min-width: $site-min-width;
}

#root {
  display: flex;
  flex-direction: column;
}
