@import '../../../styles/fonts';
@import '../../../styles/vars';

.projects-page {
  &-heading {
    @include font-heading-2;
    margin-top: 5rem;
  }

  &-projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -1.5rem;
  }

  &-tag-button {
    @include font-subtitle-1;
    background-color: #fff;
    border: 0;
    border-bottom: solid 2px $color-neutrals-grey;
    color: $color-neutrals-grey;
    flex: 1;
    padding: 1rem 1.5rem;

    &.select {
      border-bottom: solid 5px $color-primary-blue;
      color: $color-neutrals-black;
    }
  }

  &-tag-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2rem;
  }

  &-text {
    @include font-text;
    margin-top: 1.5rem;
  }

  &-title {
    @include font-heading-1;
  }

  &-mobile-dropdown {
    display: none;

    .dropdown-root {
      height: 2.5rem;
      margin-top: 1rem;

      .dropdown-control {
        border-radius: 4px;
      }

      .dropdown-arrow {
        margin-top: 0.25rem;
      }
    }
  }

  @media(max-width: $site-size-small) {
    &-projects {
      justify-content: center;
    }

    &-tag-section {
      display: none;
    }

    &-mobile-dropdown {
      display: unset;
    }
  }
}
