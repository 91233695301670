@import '../../../styles';

.error-page {
  align-items: center;
  display: flex;
  flex-direction: column;

  &-message {
    @include font-heading-2;
    margin-top: 1.375rem;
    text-align: center;
  }

  &-button {
    @include font-subtitle-1;
    background: #c4c4c4;
    border: transparent;
    cursor: pointer;
    height: 4.5rem;
    margin: 0;
    margin-top: 2rem;
    padding: 0;
    width: 26.5rem;
  }

  @media(max-width: $site-size-small) {
    &-button {
      height: 2.25rem;
      margin-top: 1rem;
      width: 14rem;
    }
  }
}
