@import '../../../styles';

.footer {
  align-items: flex-start;
  background-color: $color-primary-navy;
  color: #fff;
  display: flex;
  flex: 0 0 auto;
  height: 16rem;
  padding-left: 7rem;
  padding-top: 4rem;

  &-content {
    display: flex;
    flex-direction: column;
  }

  &-logo {
    width: 10.375rem;
  }

  &-contact {
    @include font-footer-heading-text;
    margin-top: 1rem;
  }

  &-email {
    @include font-footer-text;
    margin-top: 0.5rem;
  }

  &-links {
    display: flex;
    flex-direction: column;
    margin-left: 4rem;
  }

  &-link {
    @include font-footer-heading-text;
  }

  &-issue {
    margin-left: 4.5rem;

    &-heading {
      @include font-footer-heading-text;
    }

    &-link {
      @include font-footer-text;
      cursor: pointer;
      margin-top: 1.25rem;
      text-decoration-line: underline;
    }
  }

  @media(max-width: $site-size-small) { 
    flex-direction: column;
    height: unset;
    padding: 1.5rem;

    &-email { 
      margin-top: 0.25rem;
    }

    &-links {
      margin-left: 0;
      margin-top: 1.5rem;
    }

    &-link { 
      margin-top: 0.5rem;
    }

    &-issue {
      margin-left: 0;
      margin-top: 1.5rem;

      &-link {
        margin-top: 0.25rem;
      }
    }
  }
}
