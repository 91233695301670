@import '../../../styles';

.story-card {
  display: flex;
  margin-top: 5rem;

  &:first-child {
    margin-top: 0;
  }

  &-photo {
    box-shadow: 16px 16px $color-primary-dark-yellow;
    flex-shrink: 0;
    height: 15rem;
    object-fit: cover;
    width: 17.5rem;

    &.left {
      margin-right: 8.5rem;
    }

    &.right {
      box-shadow: 16px 16px $color-primary-navy;
      margin-left: 8.5rem;
    }
  }

  &-heading {
    @include font-heading-3;
  }

  &-quote {
    border-left: 6px solid $color-primary-dark-yellow;

    &-text {
      @include font-story-text;
      margin-left: 1rem;
      margin-top: 0.75rem;
    }

    &.right {
      border-color: $color-primary-navy; 
    }
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }

  &-name {
    @include font-subtitle-1;
  }

  &-descriptor {
    @include font-text;
    margin-top: 0.75rem;
  }

  &-link {
    &,
    &:link,
    &:visited,
    &:focus,
    &:hover,
    &:active { // Selectors are needed to override css reset styling
      @include font-linked-text;
    }

    &-arrow {
      margin-left: 0.5rem;
    }
  }

  @media(max-width: $site-size-small) {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;

    &.right {
      flex-direction: column-reverse;
    }

    &-photo {
      &.left {
        margin: 0;
      }

      &.right {
        margin: 0;
      }
    }

    &-heading {
      margin-top: 2.5rem;
    }

    &-bottom {
      display: block;
    }

    &-descriptor {
      margin-top: 0.75rem;
    }

    &-link {
      display: inline-block;
      margin-top: 0.5rem;
    }
  }
}
