@import 'vars';

$font-family: 'Inter', sans-serif;

@mixin font-display-1 {
  font-size: 64px;
  font-weight: 800;

  @media(max-width: $site-size-small) { 
    font-size: 36px;
  }
}

@mixin font-heading-1 {
  font-size: 34px;
  font-weight: 800;
    
  @media(max-width: $site-size-small) { 
    font-size: 24px;
  }
}

@mixin font-heading-2 {
  font-size: 28px;
  font-weight: 800;
    
  @media(max-width: $site-size-small) { 
    font-size: 22px;
    font-weight: 700;
  }
}

@mixin font-heading-3 {
  font-size: 20px;
  font-weight: 800;
    
  @media(max-width: $site-size-small) { 
    font-size: 17px;
    font-weight: 700;
  }
}

@mixin font-nav-bar-text {
  font-size: 18px;
  font-weight: 500;

  @media(max-width: $site-size-small) { 
    font-size: 16px;
  }
}

@mixin font-subtitle-1 {
  font-size: 17px;
  font-weight: 600;
    
  @media(max-width: $site-size-small) { 
    font-size: 16px;
  }
}

@mixin font-story-text {
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
    
  @media(max-width: $site-size-small) { 
    font-size: 16px;
  }
}

@mixin font-text {
  font-size: 15px;
  font-weight: 400;
    
  @media(max-width: $site-size-small) { 
    font-size: 14px;
  }
}

@mixin font-linked-text {
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  text-decoration: underline;
    
  @media(max-width: $site-size-small) { 
    font-size: 14px;
  }
}

@mixin font-footer-heading-text {
  font-size: 14px;
  font-weight: 700;
    
  @media(max-width: $site-size-small) { 
    font-size: 12px;
  }
}

@mixin font-footer-text {
  font-size: 14px;
  font-weight: 400;
    
  @media(max-width: $site-size-small) { 
    font-size: 12px;
  }
}

@mixin font-small-button-text {
  font-size: 12px;
  font-weight: 400;
    
  @media(max-width: $site-size-small) { 
    font-size: 10px;
  }
}
